.img-container {
  margin-right: 40px;

  @media only screen and (max-width: 700px) {
    margin: 20px 0 20px 0;
  }

  .img-team {
    width: 168px;
  }
}

.vertical-center {
  align-items: center;
  
  @media only screen and (max-width: 700px) {
    flex-wrap: wrap;
  }
}

.text-section {
  max-width: 690px;
}

.font-18 {
  font-size: 18px;
}