.nav-wrapper {
    display: flex;
    justify-content: space-between;
    background-color: none;
    padding: $page-padding;
    max-width: $page-width;
    margin: auto;
  
    .title {
      font-size: 30px;
      font-weight: 700;
      color: #fff;
    }
    .nav-section {
      display: flex;
      align-items: center;
  
      .link-item {
        color: white;
        font-weight: bold;
        font-size: 16px;
        padding: 20px;
        margin-left: 5px;
        position: relative;
        cursor: pointer;
        text-decoration: none;

        @media only screen and (max-width: 830px) {
          padding: 10px;
        }
      }
      .link-item:active,
      .link-item:hover,
      .link-active,
      .router-link-exact-active {
        color: $primary;
        transition: 0.5s;
      }
    }
  }
  
  #logo {
    width: 44px;
    margin-bottom: 10px;
    margin-right: 6px;
  }

  #twitter-icon {
    width: 24px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
      hue-rotate(125deg) brightness(103%) contrast(103%);
  }

  #discord-icon {
    width: 16px;
    margin-left: 10px;
  }

  .icon {
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
  }

  .icon-open {
    transform: rotate(180deg);
    transition: transform 0.3 ease-in-out;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    max-width: 200px;
    height: 100%;
    background-color: $dark-primary;
    top: 0;
    left: 0;
    box-shadow: 4px 0px 4px 4px rgba(21, 112, 69, 0.429);
    list-style-type: none;
    text-align: left;
    z-index: 99;
    padding-top: 20px;
    transform: translateX(-250px);
    transition: 0.5s ease all;

    li {
      color: #fff;
      font-size: 18px;
      padding-left: 0px;
      padding-bottom: 14px;
      font-weight: bold;
      cursor: pointer;
  
      .mobile-link-item {
        color: #fff;
        text-decoration: none;
  
        &:hover {
          color: $primary;
          transition: 0.3s ease all;
        }
      }
    }
  }

  .mobile-menu-open {
    transform: translateX(0px);
    transition: 0.5s ease all;
  }

  .mobile-menu-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 90;
    top: 0;
  }