.nft-88c-container-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    height: 275px;
    max-height: 500px;
    overflow-y: scroll;
    width: 50%;
    align-items: start;
    justify-content: left;
  
    .nft-container {
      margin: 2px 2px 2px 0px;
      cursor: pointer;
      
      .nft-image {
        width: 100px;
  
        @media screen and (max-width: 480px) {
          width: 75px;
        }
  
        @media screen and (max-width: 380px) {
          width: 60px;
        }
  
        &.selected {
          filter: drop-shadow(0px 0px 4px rgb(83, 159, 83));
        }
      }
  
  
      .nft-title {
        color: #fff;
        font-weight: bold;
        font-size: 16px;
  
        @media screen and (max-width: 480px) {
          font-size: 10px;
        }
      }
    }
  }

.row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    .mintButton {
      width: 10%;
      justify-content: right;
      align-items: center;
      
    }
    .subtitle {
      width: 90%;
    }
}

.align-right {
  text-align: right;
}