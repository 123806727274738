.stat-content {
    margin-top: 20px;

    .stat-line {
        margin-top: 10px;

        .subtitle {
            color: $primary;
            font-size: 18px;
        }
        
        .stat {
            font-size: 18px;
            font-weight: bold;
        }
    }
}