@import 'variables';
@import 'components';
@import '../../../node_modules/react-toastify/dist/ReactToastify.css';

html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000;
    background-image: url('../img/stars.jpg');
    background-size: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
}

.btn-ghost {
  background: none;
  width: 164px;
  border: 2px solid $primary;
  border-radius: $border-radius;
  padding: 10px 20px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &:hover {
    color: $primary;
    background-color: $ghost-btn-hover;
  }
}

.wrapper {
  padding: $page-padding;
  color: #fff;
}

.page-container {
  max-width: 900px;
  margin: auto;
}

.title {
  color: #fff;
  font-weight: bold;
  font-size: 28px;
}

.subtitle {
  font-size: 18px;
  margin: 4px 0;
}

.text {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;

  @media only screen and (max-width: 480px) {
    font-size: 16px;
  }
}

.highlight {
  color: $primary !important;
}

.section {
  margin: 20px 0 10px 0;
}

.bold {
  font-weight: bold;
}