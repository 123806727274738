// Colors
$primary: #7effb2;
$dark-primary: #5cc385;
$ghost-btn-hover: rgb(54, 54, 54);
$svg-white: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(125deg) brightness(103%) contrast(103%);

// Sizes
$border-radius: 4px;
$page-width: 900px;
$page-padding: 20px;