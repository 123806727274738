.hero-wrapper {
    background-color: none;
    padding: $page-padding;
    max-width: $page-width;
    margin: auto;
    flex-wrap: wrap;

    .text {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
        max-width: 620px;
    }

    #featured-img {
        width: 240px;
        margin-left: 40px;
        cursor: pointer;
    }
}