.filter {
    @media only screen and (max-width: 480px) {
        flex-wrap: wrap;
    }

    .left {
        //margin-right: 40px;
        min-width: 250px;
        
        .filter-wrapper {
            margin-top: 10px;
        
            .filter-section {
                display: flex;
                align-items: center;
        
                .filter-icon {
                    width: 30px;
                    filter: $svg-white;
                    margin-right: 10px;
                }
            
                .filter-title {
                    font-weight: bold;
                    font-size: 18px;
                    cursor: pointer;
                }
            }
            
            .filter-subsection {
                margin-left: 36px;
                margin-top: 10px;
                color: $primary;
        
                /* Customize the label (the container) */
                .checkbox-container {
                    //display: block;
                    position: relative;
                    padding-left: 35px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    font-size: 18px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }
                
                /* Hide the browser's default checkbox */
                .checkbox-container input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }
                
                /* Create a custom checkbox */
                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 18px;
                    width: 18px;
                    background-color: none;
                    border: solid #fff 2px;
                    border-radius: 4px;
                }
                
                /* On mouse-over, add a grey background color */
                .checkbox-container:hover input ~ .checkmark {
                    background-color: $ghost-btn-hover;
                }
                
                /* When the checkbox is checked, add a blue background */
                .checkbox-container input:checked ~ .checkmark {
                    background-color: none;
                }
                
                /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
                
                /* Show the checkmark when checked */
                .checkbox-container input:checked ~ .checkmark:after {
                    display: block;
                }
                
                /* Style the checkmark/indicator */
                .checkbox-container .checkmark:after {
                    left: 6px;
                    top: 2px;
                    width: 4px;
                    height: 8px;
                    border: solid $primary;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
        
                .filter-number {
                    color: #ccc;
                    margin-left: 10px;
                }
            }
        }
    }

    .right {
        max-width: 650px;

        @media only screen and (max-width: 480px) {
            margin-top: 20px;
        }

        .subtitle {
            font-size: 18px;
            margin: 0 0 0 20px;
        }

        .nft-container {
            display: flex;
            flex-wrap: wrap;
        }

        .nft-section {
            margin: 20px;

            @media only screen and (max-width: 914px) {
                margin: 10px;
            }

            .nft-img {
                width: 168px;
                cursor: pointer;

                @media only screen and (max-width: 706px) {
                    width: 150px;
                }

                @media only screen and (max-width: 380px) {
                    width: 120px;
                }
            }
            .nft-info {
                display: flex;
                justify-content: space-between;
                font-size: 18px;
                font-weight: 500;
            }
            .buy {
                color: $primary;
                font-size: 18px;
                font-weight: 500;
                cursor: pointer;
            }
        }

        #container {
            display: flex;

            ul {
                display: flex;
                padding: 0;
                margin: 20px auto;

                li {
                    cursor: pointer;
                    margin: 0 6px;
                    list-style-type: none;
                    font-size: 18px;
                }

                .selected {
                    color: $primary;
                    font-weight: bold;
                }
            }
        }
    }
}