.footer-wrapper {
  max-width: $page-width;
  padding: $page-padding;
  margin: auto auto 20px auto;
  color: #fff;

  .footer-section {
    display: flex;
    justify-content: center;
    margin-bottom: 4px;
    flex-wrap: wrap;

    @media only screen and (max-width: 440px) {
      text-align: center;
    }
  }

  a {
    color: $primary;
  }
}