.nft-container-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  .nft-container {
    margin: 20px 20px 10px 0px;
    cursor: pointer;
    
    .nft-image {
      width: 200px;

      @media screen and (max-width: 480px) {
        width: 150px;
      }

      @media screen and (max-width: 380px) {
        width: 120px;
      }

      &.selected {
        filter: drop-shadow(0px 0px 8px rgb(83, 159, 83));
      }
    }


    .nft-title {
      color: #fff;
      font-weight: bold;
      font-size: 28px;

      @media screen and (max-width: 480px) {
        font-size: 18px;
      }
    }
  }
}