.img-container-nftpage {
  max-width: 350px;

  @media only screen and (max-width: 380px) {
    max-width: 300px;
}
}

.trait-section {
  margin: 8px 0;
}